
<template>
    <div id="table-demo">
      <vx-card>
          <vs-table pagination :max-items="6" search :data="payments">

            <template slot="header">
              <h5 class="flex items-center mb-2">
                <feather-icon icon="ArchiveIcon" class="w-5 h-5 mr-1"></feather-icon>
                <span> Payments Recieved </span>
              </h5>
            </template>
            <template slot="thead">
                <vs-th>PymentID</vs-th>
                <vs-th>CustEmail</vs-th>
                <vs-th>Amount</vs-th>
                <vs-th>Date</vs-th>
            </template>

            <template slot-scope="{data}">
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td>
                    {{tr.paymentid}}
                </vs-td>
                <vs-td>
                    {{tr.user_email}}
                </vs-td>
                <vs-td >
                    {{tr.paid_amount | k_formatter}} <span style="text-transform: uppercase;">{{tr.currency}}</span>
                </vs-td>
                <vs-td>
                    {{tr.created_at | date_ago}}
                </vs-td>

                <template class="expand-user" slot="expand">
                  <div class="con-expand-users w-full p-3">
                    <vs-list>
                      <vs-list-item  title="Method of Payment" :subtitle="tr.method"></vs-list-item>
                      <vs-list-item title="Transaction Ref" :subtitle="tr.transref"></vs-list-item>
                      <vs-list-item title="Currency" :subtitle="tr.currency"></vs-list-item>
                      <vs-list-item title="Description" :subtitle="tr.desc"></vs-list-item>
                    </vs-list>
                    <div class="con-btns-user p-3 flex items-center justify-between">
                      <div class="flex">
                        <vs-button type="border" size="small" icon-pack="feather" icon="icon-printer" color="success" @click="printPayment(tr.id)" class="mr-2"></vs-button>
                      </div>
                    </div>
                  </div>
                </template>
              </vs-tr>
            </template>
          </vs-table>
        </vx-card>
         <vs-popup class="holamundo" @close="close" title="Invoice" :active.sync="popupInvoice">
          <form>
            <div class="vx-row">
              <div class="vx-col w-full" id="printableDIV">
                <vx-card :title="'Total Amount Paid: $'+ awaitingPrint.paid_amount +' USD'">
                  <p class="py-2"><span class="font-bold"> Method of Payment:</span> {{awaitingPrint.method}}</p>
                  <p class="py-2"><span class="font-bold"> Transaction Ref:</span> {{awaitingPrint.transref}}</p>
                  <p class="py-2"><span class="font-bold"> Currency:</span> {{awaitingPrint.currency}}</p>
                  <p class="py-2"><span class="font-bold"> Description:</span> {{awaitingPrint.desc}}</p>
                  <p class="py-2"><span class="font-bold"> Buyer Email:</span> {{awaitingPrint.user_email}}</p>
                  <p class="py-2"><span class="font-bold"> Date: </span> {{awaitingPrint.created_at | date(true)}}</p>
                </vx-card>
              </div>
            </div>
            <vs-button class="ml-auto mt-2" @click="acceptPrint()" size="small">Print</vs-button>
          </form>
        </vs-popup>
    </div>
</template>

<script>

export default{
  data() {
    return {
      payments: [],
      awaitingPrint: {},
      popupInvoice: false,
    }
  },
  methods: {
    fetchPayments() {
      this.$http.get(`/payments`)
      .then((response) => {
        if(response.data.success){
          this.payments = response.data.payments;
        }else{
          this.alertError(response.data.error)
        }
      }).catch((error) => { console.log(error) })
    },
    printPayment(payId){
        this.awaitingPrint = this.payments.find((payment)=> payment.id == payId )
        this.popupInvoice = true
    },
    close(){
      this.$vs.notify({
      title:'Notice',
      text:"You cancel an operation",
      color:'warning',
      iconPack: 'feather',
      icon:'icon-alert-circle',
      position:'bottom-right'})
      this.awaitingPrint = {}
    },
    acceptPrint(){
        document.querySelector('body').classList.toggle('onPrintContent')
        window.print();
        document.querySelector('body').classList.toggle('onPrintContent')
    },
  },
  created(){
    this.fetchPayments()
  },
}
</script>

<style type="text/css">
[dir] .vx-card .vx-card__collapsible-content .vx-card__body {
    padding-top: .5rem !important;
}

@media print {
  .onPrintContent{
    background: #fff !important;
  }

  .onPrintContent * {
    visibility: hidden;
  }

  .onPrintContent #printableDIV{
      position: absolute;
      top: 50px;
      left: 10px;
      box-shadow: none;
  }
  .onPrintContent #printableDIV *{
    visibility: visible !important;
  }
}
</style>
